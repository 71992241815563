<template>
  <v-file-input
    v-model="images"
    :rules="rules"
    accept="image/*"
    label="Imagem do post"
    color="#70445E"
    prepend-icon="mdi-paperclip"
    @change="sendImage()"
  ></v-file-input>
</template>

<script>
  import axios from 'axios'
  export default {
    name: "FileUpload",
    props: {
      limpar: Boolean
    },
    data() {
      return {
        rules: [
          value => value.size < 8000000 || 'Foto muito grande, máximo 8 MB!',
        ],
        images: [],
        carregando: false,
        webhook: 'https://discord.com/api/webhooks/1020020878932709506/_F5j-rv7C4scwC3cVI04KLVa-K0u3bP77jkT2yfd_zNHIBojoGNu6xESYUrqU6N4HNF2'
      };
    },
    watch: {
      limpar (newVal) {
        if (newVal) {
          this.images = []
        }
      }
    },
    mounted () {
    },
    methods: {
      sendImage () {
        if (this.images == null) return
        var formData = null;
        this.carregando = true;
        'use strict'
        formData = new FormData();
        formData.append("file", this.images);
        axios.post(this.webhook, formData)
        .then((r) => {
          this.carregando = false
          //console.log(r.data.attachments[0].url)
          this.$emit('imageUpload', r.data.attachments[0].url);
        })
        .catch((e) => {
          this.carregando = false
          console.log(e)
        })
      }
    }
  }
</script>

<style scoped>
  .selectable {
    cursor: pointer;
  }
</style>