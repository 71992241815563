<template>
  <div>
    <v-text-field
      v-model="title"
      solo
      placeholder="Titulo"
      class="elevation-0 mt-3 mb-0 pb-0"
    >
    </v-text-field>
    <file-upload :limpar="limparUpload" @imageUpload="imageUpload"></file-upload>
    <tiptap-vuetify
      v-model="content"
      :extensions="extensions"
      placeholder="Digite aqui..."
    />
    <v-row class="mt-2">
      <v-col cols="12">
        <v-btn
          block
          dense
          color="#70445E"
          class="white--text"
          @click="postar()"
        >
          Postar
        </v-btn>
      </v-col>
      <v-col cols="8">
        <span class="body-1" style="color: #70445E;text-decoration: underline;cursor: pointer;" @click="verPreview()">
          <strong>Pré-visualização</strong>
        </span>
      </v-col>
      <v-col cols="2" v-if="carregando">
        <span class="caption"><i>Salvando...</i></span>
      </v-col>
      <v-col cols="2" v-if="carregando">
        <v-progress-circular
          indeterminate
          color="purple"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="preview">
        <v-card class="elevation-0" style="background-color: transparent; border: 1px solid #70445E;">
          <v-card-title v-html="title"></v-card-title>
          <hr />
          <v-card-text v-html="content"></v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import FileUpload from './FileUpload.vue'
export default {
  components: { TiptapVuetify, FileUpload },
  props: {
    item: Object
  },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    title: ``,
    content: ``,
    imageUrl: '',
    carregando: false,
    preview: false,
    isPosting: false,
    edit: false,
    limparUpload: false
  }),
  mounted () {
    if (localStorage.getItem("textoSalvo")) {
      this.content = localStorage.getItem("textoSalvo")
      localStorage.setItem("textoSalvo", this.content)
      this.$emit("novoPost")
    }
    if (localStorage.getItem("tituloSalvo")) {
      this.title = localStorage.getItem("tituloSalvo")
      localStorage.setItem("tituloSalvo", this.title)
      this.$emit("novoPost")
    }
    setInterval(() => {
      if (!this.isPosting)
        this.carregando = false
    }, 1500)
    if (!this.item) {
      this.preview = true
      this.title = this.item.title
      this.content = this.item.body
      this.$emit("novoPost")
      this.edit = true
    }
  },
  watch: {
    content: function(newVal){
      if (newVal) {
        this.carregando = true
        this.autosaveContent(newVal)
      }
    },
    title: function(newVal){
      if (newVal) {
        this.carregando = true
        this.autosaveTitle(newVal)
      }
    }
  },
  methods: {
    autosaveContent (content) {
      localStorage.setItem("textoSalvo", content)
    },
    autosaveTitle (title) {
      localStorage.setItem("tituloSalvo", title)
    },
    verPreview () {
      if (this.preview) return this.preview = false
      else return this.preview = true
    },
    postar () {
      this.isPosting = true;
      var formData = null;
      this.carregando = true;
      'use strict'
      formData = new FormData();
      formData.append("Title", this.title);
      formData.append("ImageUrl", this.imageUrl);
      formData.append("Body", this.content);
      formData.append("Edit", this.edit);
      axios.post('https://laubeapi.azurewebsites.net/api/Blog/Enviar', formData, {
      // axios.post('https://localhost:44303/api/Blog/Enviar', formData, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            "Access-Control-Allow-Origin": "*"
          }
        })
        .then(() => {
          this.isPosting = false
          alert('Sucesso!')
          this.edit = false
          this.title = ''
          this.content = ``
          this.imageUrl = ''
          this.limparUpload = true
          localStorage.removeItem("textoSalvo")
          localStorage.removeItem("tituloSalvo")
          this.$emit("hideAll")
        })
        .catch((e) => {
          this.carregando = false
          alert('Falha.')
          console.log(e)
        })
    },
    imageUpload (data) {
      this.imageUrl = data
      this.limparUpload = false
    }
  }
}
</script>
<style scoped>
.image-resizer {
    display: inline-flex;
    position: relative;
    flex-grow: 0;
  }
.image-resizer .resize-trigger {
  position: absolute;
  right: -6px;
  bottom: -9px;
  opacity: 0;
  transition: opacity .3s ease;
  color: #3259a5;
}
.image-resizer:hover .resize-trigger {
  opacity: 1;
}
</style>
