<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-progress-linear
          v-if="carregandoPosts"
          indeterminate
          color="#70445E"
        ></v-progress-linear>
        <v-container>
          <v-row class="justify-center align-start mt-2">
            <v-col cols="6" class="text-center">
              <v-btn
                outlined
                color="#70445E"
                class="elevation-0"
                @click="hideAll()"
              >
              {{ textoVerPosts }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn
                outlined
                color="#70445E"
                class="elevation-0"
                @click="novoPost()"
              >
              Novo post
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="mostrarTodos">
              <v-data-table
                dense
                :headers="headers"
                :items="blogPosts"
                disable-filtering
                disable-sort
                item-key="id"
                class="elevation-0"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon> -->
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
            <hr />
            <v-col cols="12" v-if="novaPostagem">
              <span class="text-h4 laubeFont" style="color: #70445E;">Blog post</span>
              <Tiptap :item="toEdit" @novoPost="novoPost" />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <v-progress-linear
          v-if="carregandoPosts"
          indeterminate
          color="#70445E"
        ></v-progress-linear>
        <div class="container" id="topoBlog" style="margin-bottom: 20vh;">
          <v-row class="justify-center align-start mt-2">
            <v-col cols="6" class="text-center">
              <v-btn
                outlined
                color="#70445E"
                class="elevation-0"
                small
                @click="hideAll()"
              >
              {{ textoVerPosts }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-center">
              <v-btn
                outlined
                color="#70445E"
                class="elevation-0"
                small
                @click="novoPost()"
              >
              Novo post
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="mostrarTodos">
              <v-data-table
                dense
                :headers="headers"
                :items="blogPosts"
                disable-filtering
                disable-sort
                item-key="id"
                class="elevation-0"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon> -->
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
            <hr />
            <v-col cols="12" v-if="novaPostagem">
              <span class="text-h4 laubeFont" style="color: #70445E;">Blog post</span>
              <Tiptap :item="toEdit" @novoPost="novoPost" />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Quer mesmo deletar o post <blockquote><strong>{{ toDelete.title }}</strong></blockquote>?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm(toDelete)">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import axios from 'axios'
import AppBar from '../components/AppBar.vue'
import Tiptap from '../components/Tiptap.vue'
export default {
  name: 'RestritoBlog',
  components: {
    AppBar,
    Tiptap
  },
  data () {
    return {
      carregando: false,
      carregandoPosts: false,
      dialogDelete: false,
      url: null,
      acesso: {
        user: '',
        pass: ''
      },
      blogPosts: [],
      headers: [
        { text: 'Titulo', value: 'title' },
        { text: 'Criado Em', value: 'createdAt' },
        { text: 'Ações', value: 'actions' },
      ],
      editedIndex: {},
      defaultItem: {},
      toDelete: {},
      toEdit: {},
      mostrarTodos: false,
      textoVerPosts: 'Ver todos posts',
      novaPostagem: true,
    }
  },
  mounted () {
    if (!localStorage.getItem("logged")) return this.gotoPage('./')
    this.getLista()
  },
  methods: {
    getLista () {
      this.carregandoPosts = true
      axios.get('https://laubeapi.azurewebsites.net/api/Blog/')
      // axios.get('https://localhost:44303/api/Blog/')
      .then((r) => {
        this.blogPosts = r.data
        this.carregandoPosts = false
      })
      .catch((e) => {
        console.log(e)
      })
    },
    deleteItem (item) {
      this.editedIndex = this.blogPosts.indexOf(item)
      this.toDelete = item
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItemConfirm (toDelete) {
      var formData = null;
      this.carregando = true;
      'use strict'
      formData = new FormData();
      formData.append("Id", toDelete.id);
      formData.append("CreatedAt", toDelete.createdAt);
      formData.append("Title", toDelete.title);
      formData.append("Body", toDelete.body);
      axios.post('https://laubeapi.azurewebsites.net/api/Blog/Deletar/' + toDelete.id, formData, {
      // axios.post('https://localhost:44303/api/Blog/Deletar/' + toDelete.id, formData, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            "Access-Control-Allow-Origin": "*"
          }
        })
        .then(() => {
          this.carregando = false
          this.getLista()
          alert('Sucesso!')
        })
        .catch((e) => {
          this.carregando = false
          alert('Falha.')
          console.log(e)
        })
      this.closeDelete()
    },
    editItem (item) {
      this.novaPostagem = true
      this.toEdit = item
      this.mostrarTodos = false
    },
    hideAll () {
      if (this.mostrarTodos) {
        this.textoVerPosts = 'Ver todos posts'
        return this.mostrarTodos = false
      }
      else {
        this.getLista()
        this.textoVerPosts = 'Esconder todos posts'
        this.novaPostagem = false
        this.toEdit = {}
        return this.mostrarTodos = true
      } 
    },
    novoPost () {
      if (this.novaPostagem) {
        return this.novaPostagem = false
      }
      else {
        this.mostrarTodos = false
        this.textoVerPosts = 'Ver todos posts'
        this.toEdit = {}
        return this.novaPostagem = true
      }
    },
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
  },
  beforeRouteLeave (to, from, next) {
    localStorage.removeItem("logged")
    next()
  }
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
    .linkGaleria {
      display: flex;
      flex-direction:row-reverse;
      text-decoration: none;
      color:#70445E;
    }
</style>
